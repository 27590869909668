/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import axios from 'axios';

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'

import config from '../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

import { getNewsArticleEndPoint, getMakeUpData, isTab, mediaQueryRangeTab } from '../Utils/Utils';
import styled from 'styled-components';
import Modal from 'react-modal';
// import myData from './data.json';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    background-color: #EDEDED;
    max-width:780px;
    max-height: 80vh;
    overflow-y: auto;
    @media (max-width: 820px) {
        width:300px;
    }
`;
const TableContainer = styled.div`
  width: 100%;
  height: auto;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      padding: 5px;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    overflow:auto;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;
const Img = styled.img`
    height: 18px;
    width: 18px;
    @media (max-width: 820px) {
        height: 15px;
        width: 15px;
    }
`;
const TextHeading = styled.a`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin-bottom:8px !important;
  line-height:30px !important;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
      color: #000;
      text-decoration: underline !important;
  }
`;

const customStyles = {
  content: {
    borderRadius: "5px",
    inset: "10px",
    border: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'transparent',
  },
  overlay: {
    zIndex: 9999,
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(51 51 51)"
  }
};

let Controller

class DetailBoardOfDirectorsView extends React.Component {

  state = {
    renderPageBool: false,
    cookieBool: true,
    data: {},
    showPopup: false
    // data: localStorage.getItem("data") ? JSON.parse(localStorage.getItem("data")) : {},
    // data: this.props.location.state ? this.props.location.state.data : {},
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/DetailBoardOfDirectorsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = DetailBoardOfDirectorsView

        return Controller
      }

      throw e
    }
  }

  async componentWillMount() {
    console.log(this.props.location, "-=-=-=-")
    const { hash, search } = this.props.location;
    var tempObj = await getNewsArticleEndPoint(hash, search);
    axios({ method: "get", url: config.serverUrl.base + tempObj.url, })
      .then((res) => {
        var tempData = { ...res.data };
        this.setState({ data: getMakeUpData(tempData, tempObj.makeUpData) });
      })
      .catch((err) => {
        console.log(err, "-=-=-=  ERR")
      })
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60952b8da0e2655a1e1fde15'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)
        if (active.lastOne) {
          this.setState({ renderPageBool: true })
        }
        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = DetailBoardOfDirectorsView.Controller !== DetailBoardOfDirectorsView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    const imageUrl = config.serverUrl.imageUrlPrefix;
    var content = this.state.data.bio ? this.state.data.bio.split(imageUrl).join(config.serverUrl.imageBase + imageUrl) : "";

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        <Modal
          isOpen={this.state.showPopup}
          style={customStyles}
        >
          <Container>
            <a onClick={(e) => {
              e.preventDefault();
              this.setState({ showPopup: false });
            }} style={{ alignSelf: 'flex-end', textDecoration: 'none', cursor: 'pointer' }} >
              <Img loading="lazy" src='/images/closePrimary.svg' />
            </a>
            <TableContainer>
              <table>
                {this.state.data.directorships && Object.keys(this.state.data.directorships?.value || {}).map((key, i) => {
                  let row = [];
                  let details = this.state.data.directorships?.value[key] || [];
                  console.log(details);
                  details.map((item2, index) => {
                    var textalign = null;
                    if (index === 0) {
                      textalign = 'left';
                    } else if (index === 1) {
                      textalign = 'left';
                    } else {
                      textalign = 'left';
                    }

                    if (i == 0) {
                      row.push(<th style={{ padding: '20px 15px', textAlign: textalign }}>{item2}</th>);
                    } else {
                      row.push(<td style={{ padding: '20px 15px', textAlign: textalign, fontWeight: textalign === 'center' ? 'bold' : '400' }} >{item2}</td>);
                    }
                  });
                  return (<tr>{row}</tr>);
                })}
              </table>
            </TableContainer>
          </Container>
        </Modal>
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span className="af-view">
          <div>
            <MenuSection backButton={true} />
            <div id="Founder-Section" className="af-class-section af-class-article-section af-class-wf-section" style={{ paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '180px' }}>
              <div className="af-class-container w-container">
                <div className="af-class-bio-wrapper"><img style={{ marginLeft: isTab ? '0px' : '8px', marginBottom: isTab && 'unset' }} src={this.state.data.image ? config.serverUrl.imageBase + this.state.data.image : null} loading="lazy" alt className="af-class-bio-thumbnail" />
                  <div style={{ maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : '50%' }} >
                    <h2 className="af-class-h2-section-heading" style={{ marginTop: isTab && '20px', fontSize: isTab && '36px', lineHeight: isTab && '33px', marginBottom: isTab && '15px' }} >{this.state.data.name}</h2>
                    {
                      isTab
                        ?
                        <h3 style={{ fontSize: '20px', margin: 0, lineHeight: '24px', fontWeight: 'bold', marginBottom: '25px' }} >{this.state.data.field_title}</h3>
                        :
                        <h3  >{this.state.data.field_title}</h3>
                    }
                    <div className="af-class-rich-text-block af-class-bio w-richtext" style={{ fontSize: isTab && '18px', lineHeight: isTab && '32px', textAlign: isTab && 'initial' }} dangerouslySetInnerHTML={{
                      __html: content
                    }} />
                    <br />
                    {this.state.data.directorships && <TextHeading onClick={(e) => { e.preventDefault(); this.setState({ showPopup: true }); }}>Directorship and full-time positions</TextHeading>}
                  </div>
                </div>
              </div>
              <a href="#Founder-Section" data-w-id="cecbbe3d-f864-2d13-181a-b62fc6f6611a" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(DetailBoardOfDirectorsView)

/* eslint-enable */