import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Main = styled.div`
  width: 100%;
  margin-top:40px;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    padding: 10%;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 1040px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
const TextHeadingH3 = styled.h4`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin:10px 0 !important;
  @media (max-width: 1030px) {
    font-size: 16px !important;
    line-height: 24px !important;
    width: 100%;
  }
`;
const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin:10px 0 !important;
  @media (max-width: 1030px) {
    font-size: 14px !important;
    line-height: 24px !important;
    width: 100%;
  }
`;
const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;
const TextLight = styled.p`
margin:0 !important;
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  line-height: 30px !important;
  @media (max-width: 750px) {
    font-size: 14px !important;
    line-height: 24px !important;
  }
`;

const TextBook = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;
const TextClick = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 12px;
    margin-bottom: 25px;
  }
`;
const CGRSection = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    margin-bottom: 15px;
    font-size: 12px;
  }
`;

const ReportHeading = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  width: 15%;
  @media (max-width: 900px) {
    font-size: 12px;
    width: 20%;
  }
`;

const ICSSection = styled.div`
  width: 90%;
`;

const content = styled.p`
  display: flex;
  flex-direction: column;
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  margin:10px 0 !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration: underline !important;
  }
`;

export const CommitteesOfTheBoard = (props) => {
  return (<Main>
    <TextLight>
      Committees appointed by the Board focus on specific areas and take informed decisions within the framework of delegated authority, and make specific recommendations to the Board on matters in their areas or purview. All decisions and recommendations of the committees are placed before the Board for information or for approval.
      <br />
      <br />
      <TextHeadingH3>COMPOSITION OF BOARD & BOARD COMMITTEES OF DR. REDDY’S</TextHeadingH3>
      <TextHeading>Board of Directors of Dr. Reddy’s</TextHeading>
      Mr. Satish Reddy (Chairman)<br />
      Mr. G V Prasad (Co-Chairman & MD)<br />
      Mr. Leo Puri (Lead Independent Director)<br />
      Ms. Shikha Sharma<br />
      { /*Mr. Allan Oberman<br /> */ }
      Dr. K P Krishnan<br />
      Ms. Penny Wan<br />
      Mr. Arun M. Kumar<br />
      Dr. Claudio Albrecht<br />
      Dr. Alpna Seth<br />
      Mr. Sanjiv Mehta <br/>
      <br />
      <TextHeading>BOARD COMMITTEES</TextHeading>
      We have seven Board-level Committees, namely:<br />
      Sustainability and Corporate Social Responsibility Committee<br />
      Audit Committee<br />
      Nomination, Governance & Compensation Committee<br />
      Risk Management Committee<br />
      Science, Technology and Operations Committee<br />
      Stakeholders’ Relationship Committee<br />
      Banking, Authorisations and Allotment Committee<br />
      <br/>
      The members of the Committees of Board are as under:<br />
      <TextHeading>Sustainability and Corporate Social Responsibility Committee</TextHeading>
      Dr. K P Krishnan (Chairman) <br />
      Mr. G V Prasad<br />
      Mr. Satish Reddy<br />
      Mr. Sanjiv Mehta <br />
      <br />
      <TextHeading>Audit Committee</TextHeading>
      Mr. Arun M Kumar (Chairman)<br />
      Ms. Penny Wan<br />
      Ms. Shikha Sharma<br />
      Dr. K P Krishnan<br />
      <br />
      <TextHeading>Nomination, Governance & Compensation Committee</TextHeading>
      Mr. Sanjiv Mehta (Chairman)<br />
      { /* Mr. Allan Oberman<br /> */}
      Dr. K P Krishnan<br />
      Mr. Arun M Kumar<br />
      Mr. Leo Puri<br />
      <br />
      <TextHeading>Risk Management Committee</TextHeading>
      Ms. Shikha Sharma (Chairperson)<br />
      Ms. Penny Wan<br />
      Dr. Claudio Albrecht<br />
      Dr. Alpna Seth <br />
      <br />
      <TextHeading>Science, Technology And Operations Committee</TextHeading>
      { /*Mr. Allan Oberman (Chairman)<br /> */ }
      Dr. Claudio Albrecht (Chairman) <br />
      Mr. Leo Puri<br />
      Dr. Alpna Seth<br />
      Mr. Sanjiv Mehta <br />
      <br />

      <TextHeading>Stakeholders’ Relationship Committee</TextHeading>
      Mr. Leo Puri (Chairman)<br />
      Mr. G V Prasad<br />
      Mr. Satish Reddy<br />
      <br />
      <TextHeading>Banking, Authorisations and Allotment Committee</TextHeading>
      Mr. Satish Reddy (Chairman)<br />
      Mr. G V Prasad<br />
      <br />
      Please click on the links below to view the charters of our various board committees:<br />
      <TextContent
        target="_blank"
        to={{ pathname: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/audit-committee-charter.pdf`}}
      > Charter of the Audit Committee</TextContent><br />
      <TextContent target="_blank"
        to={{ pathname: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/ngc-committee-charter.pdf`}} > Charter of the Nomination, Governance & Compensation Committee</TextContent><br />
      <TextContent target="_blank"
        to={{ pathname: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/RMC Committee Charter.pdf`}} > Charter of the Risk Management Committee</TextContent><br />
      <TextContent target="_blank"
        to={{ pathname: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/sto-committee-charter.pdf`}} > Charter of the Science, Technology and Operations Committee</TextContent><br />
      <TextContent target="_blank"
        to={{ pathname: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/src-committee-charter.pdf`}} > Charter of the Stakeholders’ Relationship Committee</TextContent><br />
      <TextContent target="_blank"
        to={{ pathname: `${config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix}files/static/SCSR-Committee-Charter-19052022.pdf`}} > Charter of the Sustainability and Corporate Social Responsibility Committee</TextContent><br />
    </TextLight>
  </Main>
  )
}
