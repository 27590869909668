const globalMarkers = [
    {
        markerOffset: -15,
        name: "INDIA",
        mName: "India",
        coordinates: [78.44351609220966, 17.492441287638922],
        show: true,
        tooltipData: {
            title: "INDIA",
            address: [
                "Corporate Office<br/>Dr. Reddy’s Laboratories Ltd.<br/>8-2-337, Road No. 3,<br/>Banjara Hills, Hyderabad<br/>Telangana 500034, INDIA<br/>Tel: +91.40.4900 2900",
                "API Hyderabad Plant 1<br/> Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District Telangana,<br/> 502 325. INDIA Tel: +91.8458.283 200",
                "API Hyderabad Plant 2<br/> Dr. Reddy’s Laboratories Limited,<br/> Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112, Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, <br/>Pin Code: - 502325.",
                "API Hyderabad 3 <br/>Plot No. 116, 116A & 126C & SY No. 157 IDA Bollaram,<br/> Jinnaram Mandal Medak District <br/>Telangana 502 325.<br/> INDIA Tel: +91.8458.283 700",
                "API Nalgonda Plant Peddadevulapally<br/> Tripuraram Mandal Nalgonda District<br/> Telangana 508 207.<br/> INDIA Tel: +91.8689. 253 150",
                "API Srikakulam Plant IDA Pydibheemavaram<br/> Ransthal Mandal Srikakulam District<br/> Andhra Pradesh 532 409.<br/> INDIA Tel: +91.8942.288 131",
                "API Srikakulam Plant (SEZ) <br/>Sector No. 28 & 34 Devunipalavalasa Village<br/> Ranastalam Mandal Srikakulam District <br/>Andhra Pradesh 532 409.<br/> INDIA Tel: +91.8942.304141",
                "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA<br/>Tel: +91.8458.279 669",
                "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA<br/>Tel: +91.40.4464 2200",
                "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA<br/>Tel: +91.40.4464 3440",
                "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA<br/>Tel: +91.1795 56001",
                "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA<br/>Tel: +91.1795 650761",
                "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA<br/>Tel: +91.8913 013400",
                "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA<br/>Tel: +91.8913 013400",
                "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA<br/>Tel: +91.8942 200046",
                "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA<br/>Tel: +91 8942 304300",
                "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA",
                "Integrated Product Development Organization (IPDO)<br/>Bachupally Village<br/>Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500 123. INDIA<br/>Tel: +91.40.4434 6200",
                "IPDO, BANGALORE<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA<br/>Tel: +91.80.7102 5444",
                "Aurigene Discovery Technologies Limited<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA",
                "ADTL, HYDERABAD<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049. INDIA",
                "Technology Development Center I<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049, INDIA",
                "Technology Development Center II<br/>Plot 31A IDA,<br/>Jeedimetla<br/>Hyderabad 500 050, INDIA<br/>+91.40.2309 5272",
            ],
            mAddress: [
                { text: "Corporate Office<br/>Dr. Reddy’s Laboratories Ltd.<br/>8-2-337, Road No. 3,<br/>Banjara Hills, Hyderabad<br/>Telangana 500034, INDIA", telNo: '+91 4049002900' },
                { text: "API Hyderabad Plant 1<br/> Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District Telangana,<br/> 502 325. INDIA", telNo: "+91 8458283200" },
                { text: "API Hyderabad Plant 2<br/> Dr. Reddy’s Laboratories Limited,<br/> Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112, Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, <br/>Pin Code: - 502325." },
                { text: "API Hyderabad 3 <br/>Plot No. 116, 116A & 126C & SY No. 157 IDA Bollaram,<br/> Jinnaram Mandal Medak District <br/>Telangana 502 325.<br/> INDIA", telNo: '+91 8458283700' },
                { text: "API Nalgonda Plant Peddadevulapally<br/> Tripuraram Mandal Nalgonda District<br/> Telangana 508 207.<br/> INDIA ", telNo: '+91 8689253150' },
                { text: "API Srikakulam Plant IDA Pydibheemavaram<br/> Ransthal Mandal Srikakulam District<br/> Andhra Pradesh 532 409.<br/> INDIA ", telNo: "+91 8942288131" },
                { text: "API Srikakulam Plant (SEZ) <br/>Sector No. 28 & 34 Devunipalavalasa Village<br/> Ranastalam Mandal Srikakulam District <br/>Andhra Pradesh 532 409.<br/> INDIA ", telNo: '+91 8942304141' },
                { text: "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA", telNo: '+91 8458279669' },
                { text: "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA", telNo: '+91 4044642200' },
                { text: "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA", telNo: '+91 4044643440' },
                { text: "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA", telNo: '+91 179556001' },
                { text: "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA", telNo: '+91 1795650761' },
                { text: "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913013400' },
                { text: "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913013400' },
                { text: "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942200046' },
                { text: "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942304300' },
                { text: "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA" },
                { text: "Integrated Product Development Organization (IPDO)<br/>Bachupally Village<br/>Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500 123. INDIA", telNo: '+91 4044346200' },
                { text: "IPDO, BANGALORE<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA", telNo: '+91 8071025444' },
                { text: "Aurigene Discovery Technologies Limited<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA" },
                { text: "ADTL, HYDERABAD<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049. INDIA" },
                { text: "Technology Development Center I<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049, INDIA" },
                { text: "Technology Development Center II<br/>Plot 31A IDA,<br/>Jeedimetla<br/>Hyderabad 500 050, INDIA", telNo: '+91 4023095272' },
            ]
        }
    },
    {
        markerOffset: -15,
        name: "ALGERIA",
        mName: "Algeria",
        coordinates: [1.5828550717739438, 28.478093864671905],
        tooltipData: {
            title: "ALGERIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "AUSTRIA",
        mName: "Austria",
        coordinates: [14.682642130810335, 47.540255454222205],
        tooltipData: {
            title: "AUSTRIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "BAHRAIN",
        mName: "Bahrain",
        coordinates: [50.53230986710154, 26.006125413921207],
        tooltipData: {
            title: "BAHRAIN",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "BANGLADESH",
        mName: "Bangladesh",
        coordinates: [89.98923959058244, 23.94026822451018],
        tooltipData: {
            title: "BANGLADESH",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "BARBADOS",
        mName: "Barbados",
        coordinates: [-59.57235641963852, 13.182107137869405],
        tooltipData: {
            title: "BARBADOS",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "CAMBODIA",
        mName: "Cambodia",
        coordinates: [105.23780536003257, 12.658690837130374],
        tooltipData: {
            title: "CAMBODIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "CHILE",
        mName: "Chile",
        coordinates: [-69.99798638839374, -26.665710340383843],
        tooltipData: {
            title: "CHILE",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "CROATIA",
        mName: "Croatia",
        coordinates: [14.996056534258557, 45.13383144289863],
        tooltipData: {
            title: "CROATIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "CZECH REPUBLIC",
        mName: "Czech Republic",
        coordinates: [14.988321065329636, 49.63166426190726],
        tooltipData: {
            title: "CZECH REPUBLIC",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "DOMINICAN REPUBLIC",
        mName: "Dominican Republic",
        coordinates: [-70.1315128300175, 18.935696309152508],
        tooltipData: {
            title: "DOMINICAN REPUBLIC",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "ECUADOR",
        mName: "Ecuador",
        coordinates: [-79.17335665737419, -1.1168017002887702],
        tooltipData: {
            title: "ECUADOR",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "EL SALVADOR",
        mName: "El Salvador",
        coordinates: [-89.14598652196324, 13.846830743748516],
        tooltipData: {
            title: "EL SALVADOR",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "GHANA",
        mName: "Ghana",
        coordinates: [-1.9834321851529815, 7.82756429948923],
        tooltipData: {
            title: "GHANA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "GREECE",
        mName: "Greece",
        coordinates: [22.548940065630337, 39.517368248576645],
        tooltipData: {
            title: "GREECE",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "GUATEMALA",
        mName: "Guatemala",
        coordinates: [-90.12027794270797, 15.717535489539133],
        tooltipData: {
            title: "GUATEMALA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "HONG KONG",
        mName: "Hong Kong",
        coordinates: [114.1784265917245, 22.34476916828577],
        tooltipData: {
            title: "HONG KONG",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "INDONESIA",
        mName: "Indonesia",
        coordinates: [121.53070110377395, -3.84631801469542],
        tooltipData: {
            title: "INDONESIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "IRELAND",
        mName: "Ireland",
        coordinates: [-7.851772788627776, 53.24037458532941],
        tooltipData: {
            title: "IRELAND",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "ISRAEL",
        mName: "Israel",
        coordinates: [34.86787080059364, 30.899600846997316],
        tooltipData: {
            title: "ISRAEL",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "JORDAN",
        mName: "Jordan",
        coordinates: [36.752292456031235, 31.286302937720258],
        tooltipData: {
            title: "JORDAN",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "LITHUANIA",
        mName: "Lithuania",
        coordinates: [24.056515409612395, 55.61317902618436],
        tooltipData: {
            title: "LITHUANIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "MALAYSIA",
        mName: "Malaysia",
        coordinates: [102.25600805198258, 3.7621535279364013],
        tooltipData: {
            title: "MALAYSIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "MALDIVES",
        mName: "Maldives",
        coordinates: [73.12182547757267, -0.5470939392274514],
        tooltipData: {
            title: "MALDIVES",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "MAURITIUS",
        mName: "Mauritius",
        coordinates: [57.58621239595066, -20.11921485220298],
        tooltipData: {
            title: "MAURITIUS",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "MOROCCO",
        mName: "Morocco",
        coordinates: [-6.688701326557352, 31.89667780893471],
        tooltipData: {
            title: "MOROCCO",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "NEPAL",
        mName: "Nepal",
        coordinates: [84.06974752372942, 28.246234514180497],
        tooltipData: {
            title: "NEPAL",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "PANAMA",
        mName: "Panama",
        coordinates: [-80.04621371496061, 8.553658771829237],
        tooltipData: {
            title: "PANAMA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "PERU",
        mName: "Peru",
        coordinates: [-75.74007826548024, -10.082068385037651],
        tooltipData: {
            title: "PERU",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "PORTUGAL",
        mName: "Portugal",
        coordinates: [-9.191161412235997, 39.69754691465622],
        tooltipData: {
            title: "PORTUGAL",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "SAUDI ARABIA",
        mName: "Saudi Arabia",
        coordinates: [46.450425074188, 23.64982436353764],
        tooltipData: {
            title: "SAUDI ARABIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "SINGAPORE",
        mName: "Singapore",
        coordinates: [103.87512651950995, 1.3538093074404205],
        tooltipData: {
            title: "SINGAPORE",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "SOUTH KOREA",
        mName: "South Korea",
        coordinates: [128.3376008196855, 36.60777704423208],
        tooltipData: {
            title: "SOUTH KOREA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "SLOVAKIA",
        mName: "Slovakia",
        coordinates: [19.553368408480818, 48.74384025792117],
        tooltipData: {
            title: "SLOVAKIA",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "SWEDEN",
        mName: "Sweden",
        coordinates: [17.54008340546835, 64.52072659259242],
        tooltipData: {
            title: "SWEDEN",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "TAIWAN",
        mName: "Taiwan",
        coordinates: [121.2892249632382, 24.039962389687226],
        tooltipData: {
            title: "TAIWAN",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "TURKEY",
        mName: "Turkey",
        coordinates: [35.28544069330064, 39.15242030836463],
        tooltipData: {
            title: "TURKEY",
            address: [],
            mAddress: []
        }
    },
    {
        markerOffset: -15,
        name: "SRI LANKA",
        mName: "Sri Lanka",
        coordinates: [80.944590, 7.546888],
        tooltipData: {
            title: "SRI LANKA",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>No.60, Maligawatta Road,<br/>Colombo -10<br/>Sri Lanka<br/>Tel: 009411 2676436",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>No.60, Maligawatta Road,<br/>Colombo -10<br/>Sri Lanka", telNo: '009411 2676436' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "UNITED KINGDOM",
        mName: "United Kingdom",
        coordinates: [-3.585901210996002, 55.92909145687116],
        tooltipData: {
            title: "UNITED KINGDOM",
            address: [
                //"Dr. Reddy's Laboratories (UK) Limited<br/>6 Riverview Road,<br/>Beverley, East Yorkshire HU17 0LD<br/>UNITED KINGDOM<br/>Tel: +44(0)1223 728010",
                //"Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM",
                "R&D Center <br/> Dr. Reddy’s Laboratories (UK) Ltd <br/> 410 Cambridge Science Park, <br/>Milton Road, Cambridge, CB4 0PE,<br/> United Kingdom",
                "Dr. Reddy’s Laboratories (EU) Ltd. <br/> Steanard lane <br/> Mirfield, West Yorkshire, <br/> WF 14, 8 HZ <br/> United Kingdom",
            ],
            mAddress: [
                // { text: "Dr. Reddy's Laboratories (UK) Limited<br/>6 Riverview Road,<br/>Beverley, East Yorkshire HU17 0LD<br/>UNITED KINGDOM", telNo: '+44(0)1223 728010' },
                // { text: "Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM" },
                { text: "R&D Center <br/> Dr. Reddy’s Laboratories (UK) Ltd <br/> 410 Cambridge Science Park, <br/>Milton Road, Cambridge, CB4 0PE,<br/> United Kingdom" },
                { text: "Dr. Reddy’s Laboratories (EU) Ltd. <br/> Steanard lane <br/> Mirfield, West Yorkshire, <br/> WF 14, 8 HZ <br/> United Kingdom" },
            ],
        }
    },
    {
        markerOffset: -5,
        name: "USA",
        mName: "USA",
        coordinates: [-74.66761139608748, 40.36021222873629],
        tooltipData: {
            title: "USA",
            address: [
                "Dr. Reddy's Laboratories Inc.<br/>107 College Road East<br/>Princeton, NJ 08540. USA<br/>Tel: +1.609.375 9900<br/>www.drreddys.com/united-states",
                "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport, Louisiana 71106. USA<br/>Tel: +1.318.861 8070",
                "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA<br/>Tel: +1.518.827 7702",
                //"Technology Development Centre, Princeton<br/>303, College Road East<br/>Princeton, New Jersey 08540. USA",
                // "Brunswick Research Center, Princeton<br/>2031 US Highway 130. Unit D<br/>Monmouth Junction<br/>New Jersey 08852. USA<br/>Tel: +1.732.422 7042",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Inc.<br/>107 College Road East<br/>Princeton, NJ 08540. USA<br/>www.drreddys.com/united-states", telNo: '+1 609 375 9900' },
                { text: "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport, Louisiana 71106. USA", telNo: '+1 318861 8070' },
                { text: "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA", telNo: '+1 518 827 7702' },
                {/*  text: "Technology Development Centre, Princeton<br/>303, College Road East<br/>Princeton, New Jersey 08540. USA" },
            { text: "Brunswick Research Center, Princeton<br/>2031 US Highway 130. Unit D<br/>Monmouth Junction<br/>New Jersey 08852. USA", telNo: '+1 732 422 7042' */ },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "KAZAKHSTAN",
        mName: "Kazakhstan",
        coordinates: [66.61656149940534, 48.34646547613248],
        tooltipData: {
            title: "KAZAKHSTAN",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Representation office<br/>Almaty, 22nd line, 45<br/>(crossing of Zhandosova-Rozubakaeva),<br/>2nd floor, cab. 10, KAZAKHSTAN<br/>Tel: +7.727.245 7179",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representation office<br/>Almaty, 22nd line, 45<br/>(crossing of Zhandosova-Rozubakaeva),<br/>2nd floor, cab. 10, KAZAKHSTAN", telNo: '+7 727 245 7179' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "UZBEKISTAN",
        mName: "Uzbekistan",
        coordinates: [63.445564890942244, 42.09776265891074],
        tooltipData: {
            title: "UZBEKISTAN",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>Tashkent, Yunus Abadsky District,<br/>Niyazbek Yuli str. 1,<br/>5th floor, Room 10, UZBEKISTAN<br/>Tel: +998.71.235 59 33",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>Tashkent, Yunus Abadsky District,<br/>Niyazbek Yuli str. 1,<br/>5th floor, Room 10, UZBEKISTAN", telNo: '+998 71 235 59 33' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "RUSSIA",
        mName: "Russia",
        coordinates: [92.83987121422074, 62.43880088628713],
        tooltipData: {
            title: "RUSSIA",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>115035, 20, Building 1, Ovchinnikovskaya emb.,<br/>Business Centre Central City Tower,<br/>6th floor, Moscow, RUSSIA<br/>Tel: + 7. 495.783 29 01",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>115035, 20, Building 1, Ovchinnikovskaya emb.,<br/>Business Centre Central City Tower,<br/>6th floor, Moscow, RUSSIA", telNo: '+7 495 783 29 01' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "JAPAN",
        mName: "Japan",
        coordinates: [138.63280083336645, 36.87696011137428],
        tooltipData: {
            title: "JAPAN",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>3rd Floor, Metlife Kabutocho Building (New)<br/>5-1 Nihonbashi Kabutocho,<br/>Chuo-ku Tokyo 113-0026 JAPAN<br/>Tel: +81.3.5847 7928",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>3rd Floor, Metlife Kabutocho Building (New)<br/>5-1 Nihonbashi Kabutocho,<br/>Chuo-ku Tokyo 113-0026 JAPAN", telNo: '+81 35847 7928' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "SPAIN",
        mName: "Spain",
        coordinates: [-3.391235689938627, 39.56497855774158],
        tooltipData: {
            title: "SPAIN",
            address: [
                "Reddy Pharma Iberia<br/>S.A. Avenida Josep Tarradellas, 3808029<br/>Barcelona (España)<br/>Tel: +34 93 355 49 16<br/>Fax : +34 93 355 49 61",
            ],
            mAddress: [
                { text: "Reddy Pharma Iberia<br/>S.A. Avenida Josep Tarradellas, 3808029<br/>Barcelona (España)<br/>Fax : +34 93 355 49 61", telNo: '+34 93 355 49 16' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "FRANCE",
        mName: "France",
        coordinates: [2.4415505883137696, 46.63157885776489],
        tooltipData: {
            title: "FRANCE",
            address: [
                "Reddy Pharma SAS<br/>9 avenue Edouard Belin<br/>92500 Rueil-Malmaison<br/>Tel: + 33 (0) 1 85 78 1734<br/>Fax : +33 (0)1 47 51 38 26",
            ],
            mAddress: [
                { text: "Reddy Pharma SAS<br/>9 avenue Edouard Belin<br/>92500 Rueil-Malmaison<br/>Fax : +33 (0)1 47 51 38 26", telNo: '+33 (0) 1 85 78 1734', },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "ITALY",
        mName: "Italy",
        coordinates: [12.463017047732619, 43.06097215179718],
        tooltipData: {
            title: "ITALY",
            address: [
                "Dr.Reddy’s Srl<br/>Via L.Settala, 20<br/>20124 MILANO (Italy)<br/>Tel: +39 02 70106808<br/>Fax : +39 02 73951592",
            ],
            mAddress: [
                { text: "Dr.Reddy’s Srl<br/>Via L.Settala, 20<br/>20124 MILANO (Italy)<br/>Fax : +39 02 73951592", telNo: "+39 02 70106808", },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "SWITZERLAND",
        mName: "Switzerland",
        coordinates: [7.433926327548433, 46.952559403766095],
        tooltipData: {
            title: "SWITZERLAND",
            address: [
                "Dr. Reddy’s Laboratories SA, <br/> Grosspeteranlage 29, <br/> 4052 Basel, <br/>SWITZERLAND",
                "Dr. Reddy's Laboratories SA<br/>Elisabethenanlage 11<br/>CH-4051 Basel, SWITZERLAND<br/>Tel: +41.61.271 4754",
                "Dr. Reddy's Consumer Healthcare, <br/> 6th floor Generali Building, <br/> Avenue Perdtemps 23, 1260 Nyon, <br /> Tel:+41 61 271 00 49"
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories SA<br/>Elisabethenanlage 11<br/>CH-4051 Basel, SWITZERLAND", telNo: '+41 61 271 4754' },
                { text: "Dr. Reddy's Consumer Healthcare, <br/> 6th floor Generali Building, <br/> Avenue Perdtemps 23, 1260 Nyon", telNo: '+41 61 271 00 49' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "GERMANY",
        mName: "Germany",
        coordinates: [10.296970835111791, 51.08302214413828],
        tooltipData: {
            title: "GERMANY",
            address: [
                "betapharm Arzneimittel GmbH.<br/>Kobelweg 95<br/>86156 Augsburg<br/>Germany<br/>Tel: +49 821-74881-0",
            ],
            mAddress: [
                { text: "betapharm Arzneimittel GmbH.<br/>Kobelweg 95<br/>86156 Augsburg<br/>Germany", telNo: '+49 821-74881-0' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "COLOMBIA",
        mName: "Colombia",
        coordinates: [-72.39904094668036, 3.360434451796628],
        tooltipData: {
            title: "COLOMBIA",
            address: [
                "Av. Cra 9 #113-52 Ofc 1701,<br/>Edf. Torres Unidas 2, Bogota,<br/>Colombia<br/>Tel: +57 1 6371524",
            ],
            mAddress: [
                { text: "Av. Cra 9 #113-52 Ofc 1701,<br/>Edf. Torres Unidas 2, Bogota,<br/>Colombia", telNo: '+57 1 6371524' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "JAMAICA",
        mName: "Jamaica",
        coordinates: [-77.34495347738796, 18.139405768877598],
        tooltipData: {
            title: "JAMAICA",
            address: [
                "Dr. Reddy's Laboratories Jamaica Ltd. <br/> Ground Floor, ICWI Building <br/> 2 St Lucia Avenue, New Kingston, Kingston-5 <br/> Jamaica",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Jamaica Ltd. <br/> Ground Floor, ICWI Building <br/> 2 St Lucia Avenue, New Kingston, Kingston-5 <br/> Jamaica", telNo: '+1- 876 - 906 1692/906 7662' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "CANADA",
        mName: "Canada",
        coordinates: [-111.49991657118608, 59.6938923450828],
        tooltipData: {
            title: "CANADA",
            address: [
                "Dr. Reddy's Laboratories Canada Inc.<br/>2425 Matheson Blvd. East, 7th Floor<br/>Mississauga, Ontario<br/>L4W 5K4<br/>Tel: 289-201-2299",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Canada Inc.<br/>2425 Matheson Blvd. East, 7th Floor<br/>Mississauga, Ontario<br/>L4W 5K4", telNo: '289-201-2299' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "NEW ZEALAND",
        mName: 'New Zealand',
        coordinates: [172.04249521437066, -42.84833541664011],
        tooltipData: {
            title: "NEW ZEALAND",
            address: [
                "Dr. Reddy's New Zealand Ltd.<br/>Level 6, AMI Building, 63 Albert Street<br/>Auckland 1142, NEW ZEALAND<br/>Tel: +64 9 356 7000",
            ],
            mAddress: [
                { text: "Dr. Reddy's New Zealand Ltd.<br/>Level 6, AMI Building, 63 Albert Street<br/>Auckland 1142, NEW ZEALAND", telNo: "+64 9 356 7000" },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "AUSTRALIA",
        mName: 'Australia',
        coordinates: [132.95078847154568, -24.549767936138213],
        tooltipData: {
            title: "AUSTRALIA",
            address: [
                "Dr Reddy's Laboratories Australia Pty Ltd<br/>Suite 3.03, Level 3/390<br/>St Kilda Road, Melbourne Vic 3004, AUSTRALIA<br/>Tel: +61.383941888",
            ],
            mAddress: [
                { text: "Dr Reddy's Laboratories Australia Pty Ltd<br/>Suite 3.03, Level 3/390<br/>St Kilda Road, Melbourne Vic 3004, AUSTRALIA", telNo: '+61.383941888' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "MEXICO",
        mName: 'Mexico',
        coordinates: [-102.16719899537985, 24.182522619180787],
        tooltipData: {
            title: "MEXICO",
            address: [
                "Dr. Reddy's de Mexico<br/>Km 4.5 Carr. Fed. Cuernavaca-Cuautla,<br/>62578 CIVAC Jiutepec Morelos. MEXICO<br/>Tel: +52.777.329 3450",
                "API Cuernavaca Plant<br/>Industrias Quimicas Falcon De Mexico S.A. de C.V.<br/>Carretera Federal Cuernavaca-Cuautla<br/>KM 4.5 CIVAC, Jiutepec<br/>Morelos, Mexico 62578"
            ],
            mAddress: [
                { text: "Dr. Reddy's de Mexico<br/>Km 4.5 Carr. Fed. Cuernavaca-Cuautla,<br/>62578 CIVAC Jiutepec Morelos. MEXICO", telNo: '+52.777.329 3450' },
                { text: "API Cuernavaca Plant<br/>Industrias Quimicas Falcon De Mexico S.A. de C.V.<br/>Carretera Federal Cuernavaca-Cuautla<br/>KM 4.5 CIVAC, Jiutepec<br/>Morelos, Mexico 62578", },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "VENEZUELA",
        mName: 'Venezuela',
        coordinates: [-66.43269335263254, 7.2039160157855875],
        tooltipData: {
            title: "VENEZUELA",
            address: [
                "Dr. Reddy's Venezuela C.A.<br/>Oficina 3A, Piso – 3<br/>Edif. Centro Empresarial Premium.<br/>Av. Orinoco, Urb. Las Mercedes<br/>Caracas – 1080 Venezuela<br/>Tel: - 0058212 994.25.68",
            ],
            mAddress: [
                { text: "Dr. Reddy's Venezuela C.A.<br/>Oficina 3A, Piso – 3<br/>Edif. Centro Empresarial Premium.<br/>Av. Orinoco, Urb. Las Mercedes<br/>Caracas – 1080 Venezuela", telNo: '0058212 994 25 68' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "BRAZIL",
        mName: 'Brazil',
        coordinates: [-55.537197315614385, -8.095278623580795],
        tooltipData: {
            title: "BRAZIL",
            address: [
                "DR.REDDY´S FARMACÊUTICA DO BRASIL LTDA<br/>Av.Guido Caloi, 1.985 - Cj.11 - Jd.São Luis<br/>Cep.: 05802-140 - São Paulo - SP - Brasil<br/>Office: (+55 11) 5894-2343 / 5894-2158",
            ],
            mAddress: [
                { text: "DR.REDDY´S FARMACÊUTICA DO BRASIL LTDA<br/>Av.Guido Caloi, 1.985 - Cj.11 - Jd.São Luis<br/>Cep.: 05802-140 - São Paulo - SP - Brasil", telNo: '(+55 11) 5894-2343 / 5894-2158' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "SOUTH AFRICA",
        mName: 'South Africa',
        coordinates: [24.487927251304868, -31.042350005931578],
        tooltipData: {
            title: "SOUTH AFRICA",
            address: [
                "Dr. Reddy's Laboratories (Pty) Ltd<br/>South Wing, The Place, 1 Sandton Drive<br/>Sandton, 2196<br/>P O Box 784746, Sandton City, Sandton, 2146<br/>Tel: +27.11.783 0104<br/>www.drreddys.co.za",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories (Pty) Ltd<br/>South Wing, The Place, 1 Sandton Drive<br/>Sandton, 2196<br/>P O Box 784746, Sandton City, Sandton, 2146<br/>www.drreddys.co.za", telNo: '+27 11 783 0104' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "BELARUS",
        mName: 'Belarus',
        coordinates: [27.99716265730534, 53.161439724214326],
        tooltipData: {
            title: "BELARUS",
            address: [
                "Representation Office of \"Dr. Reddy's Laboratories Ltd\" <br/>220035, Republic of Belarus, Minsk,<br/>Timiryazeva Street 72, Office 22, 53 (7th Floor)<br/>Office +375 (17) 336-17-24 (26/28) |Fax +375 (17) 336-17-30",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>V. Khoruzey Street 22, Office 32,<br/>Minsk, 220123, BELARUS", telNo: '+375 17 283 1724' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "UKRAINE",
        mName: 'Ukraine',
        coordinates: [31.06447364087334, 48.989305609228964],
        tooltipData: {
            title: "UKRAINE",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Europa Business Centre, Office 11B,<br/>Stolichnoye Shosse, 103<br/>Kyiv 03131 UKRAINE<br/>Tel: +38 (044) 207-51-97/98<br/>Fax: +38 (044) 07-51-96",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Europa Business Centre, Office 11B,<br/>Stolichnoye Shosse, 103<br/>Kyiv 03131 UKRAINE<br/>Fax: +38 (044) 07-51-96", telNo: '+38 (044) 207-51-97/98' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "VIETNAM",
        mName: 'Vietnam',
        coordinates: [107.80725036324665, 14.623852373747498],
        tooltipData: {
            title: "VIETNAM",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Room No. 10 , M Floor,<br/>E town Building No.1,<br/>364 Cong Hoa St, Ward -13,<br/>Tan Binh District,<br/>Ho Chi Minh City<br/>Vietnam<br/>Tel: (+84) 3 8120545 – Ext 218/219<br/>Fax: (+84) 3 8125691",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Room No. 10 , M Floor,<br/>E town Building No.1,<br/>364 Cong Hoa St, Ward -13,<br/>Tan Binh District,<br/>Ho Chi Minh City<br/>Vietnam<br/>Fax: (+84) 3 8125691", telNo: '(+84) 3 8120545 – Ext 218/219' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "ROMANIA",
        mName: 'Romania',
        coordinates: [24.776718693931276, 45.805195928194],
        tooltipData: {
            title: "ROMANIA",
            address: [
                "Dr. Reddy's Laboratories Romania SRL<br/>Nicolae Caramfil st, No. 71 - 73, 5th floor,<br/>Bucharest 1<br/>Tel/Fax: +40 21 224 0032<br/>E-mail: office@drreddys.ro",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Romania SRL<br/>Nicolae Caramfil st, No. 71 - 73, 5th floor,<br/>Bucharest 1<br/>Fax: +40 21 224 0032<br/>E-mail: office@drreddys.ro", telNo: '+40 21 224 0032' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "MYANMAR",
        mName: "Myanmar",
        coordinates: [96.16241099986688, 21.83015031554986],
        tooltipData: {
            title: "MYANMAR",
            address: [
                "Dr Reddys Laboratories, Myanmar.<br/>#15-06/07, Junction City Tower<br/>No.3/A, Corner of Bogyoke Aung San Road and 27th Street<br/>Pabedan Township<br/>Yangon Region, Myanmar",
            ],
            mAddress: [
                { text: "Dr Reddys Laboratories, Myanmar.<br/>#15-06/07, Junction City Tower<br/>No.3/A, Corner of Bogyoke Aung San Road and 27th Street<br/>Pabedan Township<br/>Yangon Region, Myanmar" },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "NETHERLANDS",
        mName: "Netherlands",
        coordinates: [5.409965522017349, 52.164787278841196],
        tooltipData: {
            title: "NETHERLANDS",
            address: [
                "Dr. Reddy’s Research & Development B.V.<br/>Zernikedreef 12, 2333 CL Leiden,<br/>The Netherlands<br/>",
            ],
            mAddress: [
                { text: "Dr. Reddy’s Research & Development B.V.<br/>Zernikedreef 12, 2333 CL Leiden,<br/>The Netherlands", telNo: '' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "CHINA",
        mName: "China",
        coordinates: [102.84413692391112, 35.13746209622783],
        tooltipData: {
            title: "CHINA",
            address: [
                "Dr. Reddy's Laboratories Ltd.<br/>Beijing Representative Office<br/>Room 1509-1515, Tower A, Gemdale Center,<br/>No. 91, Jianguo Road Chaoyang District 100026<br/>Beijing, P.R. CHINA<br/>Tel: +86.10.6562 3000/ 6562 3001",
                "Kunshan Rotam Reddy Pharmaceutical Co. Limited<br/>No.258, Huang Pu Jiang (M) Road<br/>Kunshan Development Zone, Jiangsu Province 215 300<br/>P.R. CHINA<br/>www.rotamreddy.com",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Beijing Representative Office<br/>Room 1509-1515, Tower A, Gemdale Center,<br/>No. 91, Jianguo Road Chaoyang District 100026<br/>Beijing, P.R. CHINA", telNo: '+86 10 6562 3000/ 6562 3001' },
                { text: "Kunshan Rotam Reddy Pharmaceutical Co. Limited<br/>No.258, Huang Pu Jiang (M) Road<br/>Kunshan Development Zone, Jiangsu Province 215 300<br/>P.R. CHINA<br/>www.rotamreddy.com" },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "THAILAND",
        mName: "Thailand",
        coordinates: [100.80895166138838, 15.445486278066081],
        tooltipData: {
            title: "THAILAND",
            address: ["Dr. Reddy’s Laboratories (Thailand) Ltd. <br/> No. 1 Empire Tower, 16th Floor, Unit 1607-1608, South Sathorn Rd., Yannawa, Sathorn Bangkok 10120."],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "TRINIDAD",
        mName: "Trinidad",
        coordinates: [-61.23379514948604, 10.375835992463244],
        tooltipData: {
            title: "TRINIDAD",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "CYPRUS",
        mName: "Cyprus",
        coordinates: [33.23584460883729, 35.03642830415276],
        tooltipData: {
            title: "CYPRUS",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "ARGENTINA",
        mName: "Argentina",
        coordinates: [-65.26880716240265, -35.28834369512167],
        tooltipData: {
            title: "ARGENTINA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "BELGIUM",
        mName: "Belgium",
        coordinates: [4.650862613805121, 50.498846345452115],
        tooltipData: {
            title: "BELGIUM",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "BULGARIA",
        mName: "Bulgaria",
        coordinates: [25.133731209031282, 42.52525829676125],
        tooltipData: {
            title: "BULGARIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "EGYPT",
        mName: "Egypt",
        coordinates: [29.80263018126733, 26.59149765481702],
        tooltipData: {
            title: "EGYPT",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "PHILIPPINES",
        mName: "Philippines",
        coordinates: [122.94365524606803, 12.452574711173593],
        tooltipData: {
            title: "PHILIPPINES",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "PAKISTAN",
        mName: "Pakistan",
        coordinates: [69.30845569622507, 29.967594464773715],
        tooltipData: {
            title: "PAKISTAN",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "TUNISIA",
        mName: "Tunisia",
        coordinates: [9.632741197794699, 34.1276599429007],
        tooltipData: {
            title: "TUNISIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "IRAN",
        mName: "Iran",
        coordinates: [54.489537869596106, 32.14715564855912],
        tooltipData: {
            title: "IRAN",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "OMAN",
        mName: "Oman",
        coordinates: [56.08619364047557, 20.645451048805562],
        tooltipData: {
            title: "OMAN",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "GEORGIA",
        mName: "Georgia",
        coordinates: [43.52404639681433, 42.18948721628907],
        tooltipData: {
            title: "GEORGIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "MALTA",
        mName: "Malta",
        coordinates: [14.44103989258613, 35.892625046468694],
        tooltipData: {
            title: "MALTA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "POLAND",
        mName: "Poland",
        coordinates: [18.623987747699832, 52.9351412353419],
        tooltipData: {
            title: "POLAND",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "SLOVENIA",
        mName: "Slovenia",
        coordinates: [14.650027860867203, 46.059988074401126],
        tooltipData: {
            title: "SLOVENIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "HUNGARY",
        mName: "Hungary",
        coordinates: [19.607597582178364, 47.00477073834263],
        tooltipData: {
            title: "HUNGARY",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "LATVIA",
        mName: "Latvia",
        coordinates: [26.003684057414404, 57.00822589938099],
        tooltipData: {
            title: "LATVIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "UAE",
        mName: "UAE",
        coordinates: [54.178751098526675, 23.77178024146542],
        tooltipData: {
            title: "UAE",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "PALESTINE",
        mName: "Palestine",
        coordinates: [35.23315400000001, 31.952162],
        tooltipData: {
            title: "PALESTINE",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "PARAGUAY",
        mName: "Paraguay",
        coordinates: [-58.31768354424332, -23.257102848544708],
        tooltipData: {
            title: "PARAGUAY",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "SERBIA",
        mName: "Serbia",
        coordinates: [20.808691682245858, 44.1409707394039],
        tooltipData: {
            title: "SERBIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "PUERTO RICO",
        mName: "Puerto Rico",
        coordinates: [-66.62205523027907, 18.175782093239466],
        tooltipData: {
            title: "PUERTO RICO",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "ESTONIA",
        mName: "Estonia",
        coordinates: [25.853673260590735, 58.70074074967225],
        tooltipData: {
            title: "ESTONIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "DENMARK",
        mName: "Denmark",
        coordinates: [10.167728715959123, 55.49056699154742],
        tooltipData: {
            title: "DENMARK",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "LEBANON",
        mName: "Lebanon",
        coordinates: [35.85029452641589, 33.957674671060616],
        tooltipData: {
            title: "LEBANON",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "URUGUAY",
        mName: "Uruguay",
        coordinates: [-56.00973469434665, -32.809206973777265],
        tooltipData: {
            title: "URUGUAY",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "ICELAND",
        mName: "Iceland",
        coordinates: [-18.619233033147655, 65.00970034116563],
        tooltipData: {
            title: "ICELAND",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "NORWAY",
        mName: "Norway",
        coordinates: [9.360972504247489, 61.969957949646556],
        tooltipData: {
            title: "NORWAY",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "ALBANIA",
        mName: "Albania",
        coordinates: [20.08878381251845, 41.04526192476336],
        tooltipData: {
            title: "ALBANIA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "FINLAND",
        mName: "Finland",
        coordinates: [26.370199150835514, 62.01477443637061],
        tooltipData: {
            title: "FINLAND",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "KOSOVO",
        mName: "Kosovo",
        coordinates: [20.918747360241397, 42.60530123851602],
        tooltipData: {
            title: "KOSOVO",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "COSTA RICA",
        mName: "Costa Rica",
        coordinates: [-84.1475675841681, 10.382471777268371],
        tooltipData: {
            title: "COSTA RICA",
            address: [],
            mAddress: []
        },
    },
    {
        markerOffset: -5,
        name: "GUYANA",
        mName: "Guyana",
        coordinates: [-58.94850635706434, 4.809211273883827],
        tooltipData: {
            title: "GUYANA",
            address: [],
            mAddress: []
        },
    },
];

export default globalMarkers;