const externalLinks = {
    //footer Bottom links
    fbLink: 'https://www.facebook.com/Dr.ReddysLaboratoriesLtd',
    linkedInLink: 'https://www.linkedin.com/company/dr--reddys-laboratories?trk=nav_account_sub_nav_company_admin',
    twitterLink: 'https://twitter.com/drreddys',
    youtubeLink: 'https://www.youtube.com/drreddys',
    instaLink: 'https://www.instagram.com/drreddysofficial',

    //Covid Sputnik
    sputnikLink:'https://www.drreddys.com/sputnik-vaccine.aspx',

    //job-seeker
    IndiaLink: 'https://careers.drreddys.com/', //'https://careers.drreddys.com/#!/#opening',
    NorthAmericaLink: 'https://careers.drreddys.com/', //'https://jobs.jobvite.com/drreddysna/jobs/careers',

    //policies links
    privacyPolicy: "https://privacy-central.eu.securiti.ai/notice-embed/3ac215a4-af8c-4393-b01d-e7bcfc9e8c9b",
    cookiePolicy: "https://privacy-central.eu.securiti.ai/notice-embed/93a9d7b9-46c1-4269-91fa-3fc745d7cd40",

}

const countrySites = {
    australia: "https://www.drreddys.au",
    austria: 'https://www.drreddys.at',
    brazil: "https://www.drreddys.com.br/",
    cananda: 'https://www.drreddys.ca',
    chile: 'https://www.drreddys.cl/',
    china: 'https://www.drreddyschina.com',
    czech: 'https://www.drreddys.cz/',
    colombia:'https://www.drreddys.com.co/',
    denmark: 'https://www.drreddys.dk/',
    finland: 'https://www.drreddys.fi/',
    france:'https://www.reddypharma.fr/',
    germany:'https://www.betapharm.de/',
    india:"https://www.drreddys.com/india/",
    italy:'https://www.drreddys.it/',
    netherlands:'https://www.drreddys.nl/',
    new_zealand:'https://www.drreddys.co.nz',
    portugal: 'https://www.drreddys.pt/',
    romania: "https://www.drreddys.ro/",
    russia: 'https://www.drreddys.com/russia/',
    southAfrica: 'https://www.drreddys.co.za',
    spain: 'https://www.drreddys.es/',
    slovakia: 'https://www.drreddys.sk',
    sweden: 'https://www.drreddys.se/',
    ukraine:'https://www.drreddys.ua/',
    us:'https://www.drreddysusa.com',
    uk:'https://www.drreddys.uk/',
}

const serverUrl = {
    //Dev
    // base: "http://localhost:8888/drupalTest",
    // imageBase: "http://localhost:8888",
    // imageUrlPrefix: "/drupalTest/sites",
    // piFilesPrefix:'/drupalTest/sites/default/files/',
    // publicBlockedPrefix:"",
    // googleAnalyticsKey:"G-NMP9F0F8BS",

    //Prod
    // base: "https://websiteqa.mydrreddys.com:8443",
    // imageBase: "https://websiteqa.mydrreddys.com:8443",
    // imageUrlPrefix: "/sites/default/",
    // piFilesPrefix: "/sites/default/files/",
    // publicBlockedPrefix:"http://nginx",
    // googleAnalyticsKey:"G-C2R64PDFPG",

    //Prod
    base: "https://www.drreddys.com/cms",
    imageBase: "https://www.drreddys.com/cms",
    imageUrlPrefix: "/cms/sites/default/",
    piFilesPrefix: "/sites/default/files/",
    publicBlockedPrefix:"http://nginx",
    googleAnalyticsKey:"G-C2R64PDFPG",

}
//REACT JS Live URL
// base:"http://10.114.0.121:3000",
// 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

export default { externalLinks, serverUrl,countrySites };
